//jQuery
const $ = require('jquery');
global.$ = global.jQuery = $;
require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

//Bootstrap & popper
require("@popperjs/core");
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { Modal } from 'bootstrap';

//Style
import '../css/app.scss';

//Htmx
import htmx from 'htmx.org';

//Other
require('./sb-admin-2.js');
require('chosen-js');
require('datatables');
require('@fortawesome/fontawesome-free');

enginizer.init();

//Swal
var sweetAlert = require('sweetalert2');
window.swal = sweetAlert;

global.htmx = htmx;
global.bootstrap = require('bootstrap/dist/js/bootstrap.bundle')

htmx.on('htmx:afterRequest', evt => {
    let url;
    if (url = evt.detail.xhr.getResponseHeader('x-new-page')) {
        window.open(url, '_blank');
        window.focus();
    }

    if (evt.detail.xhr.getResponseHeader('x-reload-widgets')) {
        enginizer.helpers.reloadAllWidgets([], true)
    }
})
